import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`Area B`}</MDXTag>
<MDXTag name="p" components={components}>{`Few traces of habitation appear in Area B from the earliest periods at Gabii. A few probable postholes and drainage channels remain in the bedrock, but they are not preserved to the extent that we can reconstruct huts like we can in Area A. In the early 3rd century BCE, a house we call the “Tincu” house was constructed. It was comprised of just over half a dozen rooms around an open courtyard containing a well. It is a much better-preserved and larger house than that of Area A, and it can give us a sense of what the Area A house might once have looked like. `}</MDXTag>
<MDXTag name="p" components={components}>{`At some point in the 1st century BCE, the “Tincu” house and the Area A house nextdoor were abandoned, changing this residential block into an empty lot. Roofs caved in and stone walls collapsed. In some of the rooms of both houses, dumps of rubble and pottery suggest that the area had become a useful location for the residents still living at Gabii to dispose of their trash. Soil from higher up the hillside washed down the slope in seasonal storms and over the next few decades the houses mostly disappeared. `}</MDXTag>
<MDXTag name="p" components={components}>{`It was at this time that the people who still lived at Gabii began to make use of this empty lot for two parallel purposes. The first, is as a prime location to create a quarry very close to the urban core of the city. The bedrock, a kind of volcanic stone called tuff, sits very close to the walking surface and so was easily exploited for large blocks of quality stone. Two large quarry pits reflect how important the stone must have been for Gabii’s new industrial economy. The paved road stretching north up the slope alongside these quarry pits made a convenient connection between them and a major thoroughfare leading to the surrounding cities of Rome, Tibur, and Praeneste. The quarrying activity seems to have been thriving: the area was fully exploited with large, deep quarry pits equipped with cranes and lifting machinery that allowed the carved blocks to be moved around. In this landscape bursting with industrial activity, there was also space for another use of the city block.`}</MDXTag>
<MDXTag name="p" components={components}>{`The second use for this empty lot was as a burial ground for the local residents. Gabines began burying their dead precisely where the two earlier houses had previously been. Over the course of several hundred years, 30 men and women of various ages were buried close to each other. The bodies were laid in simple rectangular trenches in the ground, oriented both roughly east-west and north-south, and many were covered with re-used terracotta roof tiles to create a kind of A-frame roof above each body. Some of the tiles were large flat pan tiles which were still whole; some of the tiles had been broken in half in an earlier era, maybe when they were removed or fell off of the buildings they once covered. This method of burial and treatment of the deceased suggests that these individuals and their families were not the wealthy elite of Gabii, but rather, a more middling class. They buried their loved ones with a certain amount of care, but did not bury them with precious objects or in purpose-built sarcophagi. Some of the bodies did not have any trace of burial covering: it is possible that the tiles or stones which once protected the bodies were destroyed and dragged away by the agricultural plows which tilled the soil up until the very recent day, once people had abandoned the area completely.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/area_b-housetombs.png","alt":null}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"","alt":null}}></MDXTag></MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-with-background","title":"Area B","backgroundImage":"/assets/areab_phase3.jpg","key":"area-b","parentKey":"excavation-areas"};

  